'use client'

import { Button, Cell, InfoBanner, InputText, Spacer, Text, Validation } from '@vinted/web-ui'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { useDataDomeCaptcha } from '@marketplace-web/domain/data-dome'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { useFormValidationMessage } from '@marketplace-web/shared/ui-helpers'
import { resetPassword } from 'data/api/account'

type FormData = {
  email: string
}

const TRANSLATION_PREFIX = 'auth.forgot_password'

type Props = {
  // TODO: remove when non_native_flow AB test is scaled
  isAuthPage?: boolean
}

const ResetPassword = ({ isAuthPage }: Props) => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { isSubmitting, errors },
  } = useForm<FormData>()

  const translate = useTranslate(TRANSLATION_PREFIX)
  const getErrorMessage = useFormValidationMessage(errors, `${TRANSLATION_PREFIX}.fields`)
  const emailErrorMessage = getErrorMessage('email')

  const [showSuccess, setShowSuccess] = useState(false)

  async function handleFormSubmit({ email }: FormData) {
    await resetPassword({ email })

    setShowSuccess(true)
  }

  useDataDomeCaptcha(() => {
    handleFormSubmit(getValues())
  })

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="u-ui-padding-horizontal-large">
        <Text
          as="h1"
          id="auth_modal_title"
          text={translate('title')}
          type={Text.Type.Heading}
          width={Text.Width.Parent}
          alignment={isAuthPage ? Text.Alignment.Left : Text.Alignment.Center}
        />
      </div>
      <Spacer size={Spacer.Size.Large} />
      {showSuccess && (
        <InfoBanner
          testId="reset-password-success-note"
          body={translate('success_note')}
          type={InfoBanner.Type.Success}
        />
      )}
      <Spacer />
      <InputText
        {...register('email', {
          required: true,
        })}
        placeholder={translate('fields.email.title')}
        validation={emailErrorMessage && <Validation text={emailErrorMessage} theme="warning" />}
      />
      <Cell>
        <Button
          text={translate('actions.submit')}
          type={Button.Type.Submit}
          styling={Button.Styling.Filled}
          disabled={isSubmitting}
          isLoading={isSubmitting}
          onClick={(event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            if (isSubmitting) {
              event.preventDefault()
            }
          }}
        />
      </Cell>
    </form>
  )
}

export default ResetPassword
