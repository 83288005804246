import { debounce } from 'lodash'

import { isEmailValid } from '@marketplace-web/shared/email-validation'

import { validateUser } from 'data/api'
import { ValidateUserArgs } from 'types/api/args'

import { isLoginValid } from '../../utils/login'
import { isFullNameValid } from '../../utils/fullName'

const DEBOUNCE_DELAY = 500

function asyncDebounce<Args extends Array<unknown>, ReturnType>(
  func: (...args: Args) => Promise<ReturnType>,
  wait: number,
) {
  const debounced = debounce(
    (resolve: (value: ReturnType) => void, args: Args) => {
      func(...args).then(resolve)
    },
    wait,
    { leading: true },
  )

  return (...args: Args) =>
    new Promise<ReturnType>(resolve => {
      debounced(resolve, args)
    })
}

const validateAsync = async (field: keyof ValidateUserArgs['user'], value: string) => {
  const response = await validateUser({ user: { [field]: value } })

  if ('errors' in response) {
    return response.errors.find(error => error.field === field)?.value ?? true
  }

  return true
}

const validateLoginAsync = asyncDebounce(
  (value: string) => validateAsync('login', value),
  DEBOUNCE_DELAY,
)

const validateRealNameAsync = asyncDebounce(
  (value: string) => validateAsync('real_name', value),
  DEBOUNCE_DELAY,
)

const validatePasswordAsync = asyncDebounce(
  (value: string) => validateAsync('password', value),
  DEBOUNCE_DELAY,
)

export const validateRealName = (value: string) =>
  isFullNameValid(value) && validateRealNameAsync(value)

export const validateEmail = (value: string) => isEmailValid(value)

export const validateLogin = (value: string) => isLoginValid(value) && validateLoginAsync(value)

export const validatePassword = (value: string) => validatePasswordAsync(value)
